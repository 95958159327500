[data-speed] {
    bottom: 0
}

.disable-hover {
    pointer-events: none
}

.table {
    display: table;
    width: 100%;
    height: 100%
}

.cell {
    display: table-cell;
    vertical-align: middle;
    width: 100%;
    height: 100%
}

.link-shadow {
    z-index: 9999;
    opacity: 0;
    filter: alpha(opacity=0)
}

.plLayer {
    position: absolute
}

.Parallax {
    color: #ffffff;
    font-family: 'PT Sans', Tahoma, sans-serif
}

.Parallax a {
    color: #ffffff;
    text-decoration: none;
    border: none
}

.Parallax h1,
.Parallax h2,
.Parallax .h1 {
    font: 500% 'Geometria-Medium', Arial, sans-serif;
    font-style: normal;
    text-transform: uppercase;
    line-height: 1.2;
    left: auto;
    letter-spacing: .075em;
    text-align: left
}

.topCaption h1,
.topCaption h2,
.topCaption .h1 {
    position: absolute;
    top: 10.5%;
    bottom: auto;
    left: 7.7%
}
#intro h1 {
    width: 80%;
font-family: 'Open Sans', sans-serif;
text-transform: initial;letter-spacing: 0px;
line-height:0.85;
font-weight: 300;}

.intro-pic {
    height: 100%;
    top: 0;
    max-height: 993px
}
.xtreem-title{font-size:800% !important; font-family: xtreem !important;text-transform:initial !important;}
.test-subject {
    height: 106%;
    max-height: 1053px;
    bottom: -6%;
    top: auto
}

.bcode-logo {
    position: absolute;
    top: 60%;
    left: 33%;
    width: 50%;
    height: 24%;
    background: url('../../img/mainPage/bcode/bcode_logo.png') center no-repeat;
    background-size: contain;
    border: none;
    color: transparent;
    font-size: 0;
    line-height: 0
}

.bcodeLayer {
    bottom: -2.3%;
    left: 50%;
    height: 90.8%;
    -moz-transform: translateX(-46.3%);
    -ms-transform: translateX(-46.3%);
    transform: translateX(-46.3%)
}

@media \0screen {
    .bcode-logo {
        display: none
    }
    .bcodeLayer {
        left: 8.2%
    }
}

.kynsi-pic {
    width: auto;
    height: 100%
}

.kynsi-text {
    position: absolute;
    left: 12.5%;
    bottom: 20%;
    width: 75%
}

.kynsi-text-img {
    display: block;
    width: 100%;
    height: auto !important
}

:root .DT-img {
    width: 100%
}

.DT-img {
    display: inline-block;
    max-width: 60%;
    height: auto
}

.DT-logo {
    max-width: 30%
}

.DT-upperImage {
    margin-top: -50px
}

.DT-frontImg {
    max-width: 100%;
    margin-top: -75px
}

.kis-logo {
    display: block;
    width: 67%;
    margin: 0 0 1%
}

.kisLayer01 {
    left: 9.3%;
    top: 50%;
    width: 86.6%;
    margin-top: -27%;
    opacity: .71;
    filter: alpha(opacity=0.7)
}

.kisLayer02 {
    left: 6.67%;
    top: 50%;
    width: 90.2%;
    margin-top: -22%;
    opacity: .52;
    filter: alpha(opacity=0.5)
}

.kisLayer03 {
    left: 7.66%;
    top: 50%;
    width: 78.2%;
    margin-top: -20%
}

.kisLayer04 {
    top: 50%;
    left: 25%;
    width: 46.2%;
    margin-top: -10%
}
.divlogo {
    position: relative;
    top: 43.5%;
    height: 25%;
}
.div360logo {
    position: relative;
    top: 58%;
    height: 15%;
}
.duaneLogo{position: relative;
    top: 20%;
    max-height: 50%;
    max-width: 85%;
    width:auto!important;
}
.carroDuane{top:10px;}
.downelements{position:absolute !important;bottom:0 !important; height:100%;}
.circlesini{height: 135%;top:0;}
.divmodel {
    height: 100%;
}
.bilet2uLogo {
    position: relative;
    top: 43.5%;
    height: 13%
}
.b2u-man {
    height: 100%
}

.b2u-l-1 {
    min-width: 52%;
    min-height: 60%
}

.b2u-l0 {
    min-width: 90%;
    min-height: 100%
}

.b2u-l1 {
    min-width: 90%;
    min-height: 100%
}

.b2u-confetti {
    overflow: hidden;
    width: 100%;
    height: 100%
}
#botao{background: #adc80a;
    color: #FFF;
    width: 50px;
    -moz-box-sizing: border-box;
         box-sizing: border-box;
    height: 50px;font-size:25px;}
.responsive{position: fixed; top:0; text-align:right; width:100%; z-index: 999999;}
.ceramica{top:0; height:80%;}
.camera{max-width:100%; height:auto;}
.cafe{width:auto; max-width:40%; height:auto; position:absolute; bottom:0;left:20%;}
.notebook{height:50%;bottom:18%;}
.soci-back{top:25px !important; right:5px !important;}
.soci-front{height:40%;}
.folder{height:75%;}
.garrafa{bottom: 10%;
    position: absolute;
    left: 20%;
    height: 50%;}
.back-fol{height: 100%;
    bottom: 0;
    position: absolute !important;
    left: 30% !important;}
.mat-front{    height: 120%;
    left: 0% !important;}
.fitafrente{top: 0 !important;}
.fitatras{top: 0 !important;}
.guriacozinha{bottom:5% !important; height: 75%;}
.aviaofrente{height: 45%;
    bottom: 10%;
    left: 8% !important;
position:absolute;}
.aviaotras{height:100%;}
.verdinsocial{height:100%;}
.mao{right:5%;position:absolute;height:80%;bottom:0;}
.b2u-r-specle {
    min-width: 22%;
    min-height: 33%
}

.b2u-y-specle {
    position: absolute;
    bottom: 0;
    right: 0;
    min-width: 46%;
    min-height: 45%
}

.left-arrow,
.right-arrow {
    position: fixed;
    top: 0;
    z-index: 8020;
    display: none;
    width: 180px;
    height: 100%;
    background: none repeat scroll 0 0 transparent;
    border: medium none;
    cursor: pointer;
    text-align: center;
    text-decoration: none
}

.left-arrow>img,
.right-arrow>img {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 50px;
    height: 80px;
    margin-top: -40px;
    margin-left: -20px
}

.left-arrow.touch,
.right-arrow.touch {
    position: absolute;
    top: 50%;
    width: 80px;
    height: 80px;
    margin-top: -40px
}

.left-arrow.touch>img,
.right-arrow.touch>img {
    position: static;
    margin: 0
}

.left-arrow {
    top: 10.5%;
    bottom: 0;
    left: 0;
    height: auto;
    margin-top: 190px
}

.left-arrow>img {
    top: 44.75%;
    margin-top: -150px
}

.left-arrow.touch {
    left: 60px
}

.left-arrow:hover>img {
    margin-left: -30px;
    -moz-transition: margin-left 0.3s ease-out;
    transition: margin-left 0.3s ease-out
}

.right-arrow {
    right: 0
}

.right-arrow.touch {
    right: 60px
}

.right-arrow:hover>img {
    margin-left: -10px;
    -moz-transition: margin-left 0.3s ease-out;
    transition: margin-left 0.3s ease-out
}
.responsive{display:none;}
@media screen and (max-width: 768px) {
.socials{display:none !important;}
.ceramica{display: none !important;}
}
@media screen and (max-width: 600px) {
    #footer{display:none;}
    .responsive{display:block;}
}
@media screen and (max-width: 500px) {
.notebook{display:none !important;}
.aviaofrente{display: none !important;}
.garrafa{display:none !important;}
.back-fol{display:none !important}
}

@media screen and (max-width: 321px) {
    .left-arrow.touch {
        left: 0
    }
    .right-arrow.touch {
        right: 0
    }
}

.nav-animation {
    position: fixed;
    right: 140px;
    top: 50%;
    margin-top: -40px;
    left: auto !important;
    display: none;
    width: 80px;
    height: 80px
}

.nav-animation.touch {
    display: none !important
}

.nav-animation>img {
    display: block;
    position: absolute;
    top: 0;
    left: 0
}

.nav-animation>.active {
    display: none
}

.unloaded {
    height: 100%;
    overflow: hidden
}

.loadBackground,
.Preloader {
    position: absolute;
    top: 0px;
    right: 0;
    bottom: 0;
    left: 0px;
    z-index: 2;
    height: 100%;
    width: 100%
}

.loadBackground {
    z-index: 1;
    background-color: #000000;
    display: block
}

.Preloader {
    background: rgba(0, 0, 0, 0.3)
}

.Preloader:after {
    display: inline-block;
    vertical-align: middle;
    width: 0;
    height: 100%;
    content: ''
}

.Preload-end,
.Preload-start {
    top: 0;
    overflow: hidden;
    display: inline-block;
    vertical-align: middle;
    width: 0
}

.Preload-endImg,
.Preload-startImg {
    width: 200px;
    height: 51px
}

.Preload-startImg {
    float: right
}

.Preload-endImg {
    float: left
}